import {
  Component, Input, Output, OnInit, EventEmitter,
} from '@angular/core';
import {
  animate, style, transition, trigger,
} from '@angular/animations';

export interface ModalOptions {
  values: string[];
  callback: (value:string) => void;
}

@Component({
  selector: '[modal]',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  animations: [
  trigger('fade', [
    transition('void => *', [
      style({ opacity: 0 }),
      animate(200, style({ opacity: 1 })),
      ]),
    transition('* => void', [
      animate(300, style({ opacity: 0 })),
      ]),
    ]),
  trigger('slide', [
    transition('void => slideIn', [
      style({ transform: 'translateX(100%)' }),
      animate('500ms cubic-bezier(0.25, 1, 0.5, 1)', style({ transform: 'translateX(0)' })),
      ]),
    transition('void => slideOut', [
      style({ transform: 'translateX(0)' }),
      animate('500ms cubic-bezier(0.25, 1, 0.5, 1)', style({ transform: 'translateX(100%)' })),
      ]),
    ]),
  ],
  })
export class ModalComponent implements OnInit {
  constructor() {}
    @Input() align: string;
    @Input() title: string;
    @Input() show: boolean;
    @Input() full: boolean;
		@Input() topAlignMod: string;
    @Input() showDoneBtn: boolean;
		@Input() btnDisabled: boolean;
    @Output() showChange = new EventEmitter<boolean>();
    @Output() onClose = new EventEmitter();
    @Input() allowInternalHiding = true;
    @Input() functionDone: () => void;
    @Input() options: ModalOptions;
    optionsOpen: boolean = false;
    slideAction: string;

    ngOnInit(): void {
      if (this.align === 'right') {
        this.slideAction = 'slideIn';
      }
    }

    close() {
      this.showChange.emit(false);
      this.onClose.emit();
    }

  /* Instructions to use buttons

  -- For "done" button use [functionDone]="yourProperty" in the template in modal
  -- For "options" button use [options]="yourProperty" in the template in modal

  - Then list your properties in your component as follows

    this.menuItemModalOptions = {
      values: ['Activate', 'Delete'],
      callback: (value:string) => {
        console.log('got option click', value);
        switch (value) {
          case 'Activate':
            console.log('activate');
            break;
          case 'Delete':
            console.log('delete');
            break;
          default:
            break;
        }
      },
    };

  */
}
