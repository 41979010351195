<div class="wrapper" 
	[ngClass]="{'show': show, 'center': align == 'center', 'showDoneBtn': showDoneBtn}">
	<div (click)="close()" class="close-layer"></div>
  <div *ngIf="show" class="modal" 
		[ngClass]="{'full': full}" 
		[@slide]="slideAction"
		[ngStyle]="{'top': topAlignMod}">
		<div class="header">
      <span *ngIf="allowInternalHiding" (click)="close()" class="mdi mdi-close"></span>
			<article class="header-data">
        <h3 *ngIf="title">
          {{ title }}
        </h3>
        <ng-content select="[subtitle]"></ng-content>
      </article>
      <div>
        <div *ngIf="functionDone && btnDisabled" class="disabled-button">Done</div>
				<div *ngIf="functionDone && !btnDisabled" (click)="functionDone()" class="done-button">Done</div>
        <article class="options-button" *ngIf="options" (click)="optionsOpen = !optionsOpen" tabindex="0">
          <span></span><span></span><span></span>
          <article class="options-container" *ngIf="optionsOpen">
            <div *ngFor="let val of options.values" (click)="options.callback(val)">{{ val }}</div>
          </article>
        </article>
      </div>
      <!-- <article class="unselect"></article> -->
		</div>
		<div class="body">
			<ng-content></ng-content>
		</div>
	</div>
</div>
