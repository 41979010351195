import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MatDatepickerModule } from '@angular/material/datepicker';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
// import { FirebaseApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';
// import { initializeAnalytics, provideAnalytics } from '@angular/fire/analytics';
import { PromoIsModeratedPipe } from './pipes/promo-is-moderated.pipe';

import { BUILD_COMMIT, BUILD_SOURCE } from 'src/ver';
import { environment } from 'src/environments/environment';
// import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ContextMenuModule } from '../context-menu/ngx-contextmenu';

import { TimePipe } from './pipes/time.pipe';
import { PasswordStrengthPipe } from './pipes/password-strength.pipe';
import { RolePipe } from './pipes/user/role.pipe';
import { BasicNumPipe } from './pipes/basic-num.pipe';
import { BasicNumV2Pipe } from './pipes/basic-num-v2.pipe';
import { TimePeriodPipe } from './pipes/time-period.pipe';
import { EmailColorPipe } from './pipes/email-color.pipe';
import { TruncatePipe } from './pipes/truncate.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { RoundOrDeci } from './pipes/round-or-deci.pipe';
import { RoundOrDeciOpcards } from './pipes/round-or-deci-opcards.pipe';
import { FoodRating } from './pipes/food-rating.pipe';
import { TimeStamp } from './pipes/time-stamp.pipe';
import { SalesBlockNumPipe } from './pipes/sales-block-num.pipe';
import { RestaurantRatingCountPipe } from './pipes/restaurant-rating-count.pipe';
import { CustomDatePipe } from './pipes/custom-date.pipe';
import { LegendCustomDatePipe } from './pipes/legend-custom-date.pipe';
import { AvatarPipe } from './pipes/avatar.pipe';

import { LongPressDirective } from './directives/long-press.directive';
import { MayViewDirective } from './directives/may-view.directive';
import { InputRefDirective } from './directives/input-ref.directive';
import { UserInteractionMetricDirective } from './directives/user-interaction-metric.directive';
import { ProfileColorDirective } from './directives/profile-color.directive';
import { ClickOutsideDirective } from './directives/click-outside.directive';

import { FormRowComponent } from './components/form-row/form-row.component';
import { PagerComponent } from './components/pager/pager.component';
import { RestaurantSelectorComponent } from './components/restaurant-selector/restaurant-selector.component';
import { PeriodSelectorComponent } from './components/period-selector/period-selector.component';
import { SortbySelectorComponent } from './components/sortby-selector/sortby-selector.component';
import { ImpersonationComponent } from './components/impersonation/impersonation.component';
import { BannerComponent } from './components/banner/banner.component';
import { BannerCofundedComponent } from '../promos/components/banner-cofunded/banner-cofunded.component';
import { ModalComponent } from './components/modal/modal.component';
import { RestaurantRatingsComponent } from './components/restaurant-ratings/restaurant-ratings.component';
import { NavTabsComponent } from './components/nav-tabs/nav-tabs.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { SelectComponent } from './components/inputs/select/select.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { MobileFilterDrawerComponent } from './components/mobile-filter-drawer/mobile-filter-drawer.component';
import { DrawerComponent } from './components/drawer/drawer.component';
import {
  DrawerHeaderTop, DrawerHeaderBottom, DrawerBody, DrawerFooter,
} from './components/drawer/drawer.directives';
import { BackdropComponent } from './components/backdrop/backdrop.component';
import { LoaderComponent } from './components/loader/loader.component';
import { JsonEditComponent } from './components/json-edit/json-edit.component';
import { ObjectKeysPipe } from './pipes/object-keys.pipe';

// inputs
import { NumberInputComponent } from './components/inputs/number-input/number-input.component';
import { CurrencyInputComponent } from './components/inputs/currency-input/currency-input.component';
import { PercentageInputComponent } from './components/inputs/percentage-input/percentage-input.component';
import { PercentageRangeInputComponent } from './components/inputs/percentage-range-input/percentage-range-input.component';
import { TimeInputComponent } from './components/inputs/time-input/time-input.component';
import { TimeRangeInputComponent } from './components/inputs/time-range-input/time-range-input.component';
import { DateInputComponent } from './components/inputs/date-input/date-input.component';
import { DateRangeInputComponent } from './components/inputs/date-range-input/date-range-input.component';
import { LineChartComponent } from 'src/app/modules/dashboard/components/linechart/linechart.component';

// const firebaseApp = initializeApp(environment.firebase);

@NgModule({
  imports: [
		RouterModule,
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		ContextMenuModule.forChild(),
		NgxMaterialTimepickerModule,
		MatNativeDateModule,
		MatDatepickerModule,
		MatFormFieldModule,
		MatInputModule,
		MatIconModule,
		NgxDaterangepickerMd.forRoot(),
  ],
  declarations: [
		LongPressDirective,
		TimePipe,
		RolePipe,
		PagerComponent,
		PasswordStrengthPipe,
		MayViewDirective,
		FormRowComponent,
		InputRefDirective,
		BasicNumPipe,
		BasicNumV2Pipe,
		RestaurantSelectorComponent,
		PeriodSelectorComponent,
		SortbySelectorComponent,
		TimePeriodPipe,
		SalesBlockNumPipe,
		ImpersonationComponent,
		UserInteractionMetricDirective,
		EmailColorPipe,
		ProfileColorDirective,
		ClickOutsideDirective,
		BannerComponent,
		BannerCofundedComponent,
		RestaurantRatingCountPipe,
		CustomDatePipe,
		LegendCustomDatePipe,
		ModalComponent,
		RestaurantRatingsComponent,
		AvatarPipe,
		TruncatePipe,
		NavTabsComponent,
		DropdownComponent,
		SafePipe,
		SpinnerComponent,
		BackdropComponent,
		MobileFilterDrawerComponent,
		DrawerComponent,
		DrawerHeaderTop,
		DrawerHeaderBottom,
		DrawerBody,
		DrawerFooter,
		LoaderComponent,
		SelectComponent,
		JsonEditComponent,
		ObjectKeysPipe,
		PromoIsModeratedPipe,
		NumberInputComponent,
		CurrencyInputComponent,
		PercentageInputComponent,
		PercentageRangeInputComponent,
		TimeInputComponent,
		TimeRangeInputComponent,
		DateInputComponent,
		DateRangeInputComponent,		
  LongPressDirective,
  TimePipe,
  RolePipe,
  PagerComponent,
  PasswordStrengthPipe,
  MayViewDirective,
  FormRowComponent,
  InputRefDirective,
  BasicNumPipe,
  BasicNumV2Pipe,
  RestaurantSelectorComponent,
  PeriodSelectorComponent,
  SortbySelectorComponent,
  TimePeriodPipe,
  SalesBlockNumPipe,
  ImpersonationComponent,
  UserInteractionMetricDirective,
  EmailColorPipe,
  ProfileColorDirective,
  ClickOutsideDirective,
  BannerComponent,
  BannerCofundedComponent,
  RestaurantRatingCountPipe,
  CustomDatePipe,
  LegendCustomDatePipe,
  ModalComponent,
  RestaurantRatingsComponent,
  AvatarPipe,
  TruncatePipe,
  NavTabsComponent,
  DropdownComponent,
  SafePipe,
	RoundOrDeci,
	RoundOrDeciOpcards,
	FoodRating,
	TimeStamp,
  SpinnerComponent,
  BackdropComponent,
  MobileFilterDrawerComponent,
  DrawerComponent,
  DrawerHeaderTop,
  DrawerHeaderBottom,
  DrawerBody,
  DrawerFooter,
  LoaderComponent,
  SelectComponent,
  JsonEditComponent,
  ObjectKeysPipe,
  PromoIsModeratedPipe,
	LineChartComponent,
  ],
  exports: [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  NgxMaterialTimepickerModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatIconModule,
  LongPressDirective,
  TimePipe,
  RolePipe,
  PagerComponent,
  PasswordStrengthPipe,
  MayViewDirective,
  FormRowComponent,
  InputRefDirective,
  BasicNumPipe,
  BasicNumV2Pipe,
  RestaurantSelectorComponent,
  PeriodSelectorComponent,
  SortbySelectorComponent,
  TimePeriodPipe,
  SalesBlockNumPipe,
  ImpersonationComponent,
  UserInteractionMetricDirective,
  EmailColorPipe,
  ProfileColorDirective,
  ClickOutsideDirective,
  BannerComponent,
  BannerCofundedComponent,
  CustomDatePipe,
  LegendCustomDatePipe,
  ModalComponent,
  RestaurantRatingsComponent,
  AvatarPipe,
  TruncatePipe,
  SafePipe,
	RoundOrDeci,
	RoundOrDeciOpcards,
	FoodRating,
	TimeStamp,
  NavTabsComponent,
  DropdownComponent,
  MatFormFieldModule,
  MatInputModule,
  NgxDaterangepickerMd,
  SpinnerComponent,
  BackdropComponent,
  MobileFilterDrawerComponent,
  DrawerComponent,
  DrawerHeaderTop,
  DrawerHeaderBottom,
  DrawerBody,
  DrawerFooter,
  LoaderComponent,
  SelectComponent,
  JsonEditComponent,
  ObjectKeysPipe,
  PromoIsModeratedPipe,
	NumberInputComponent,
	CurrencyInputComponent,
	PercentageInputComponent,
	PercentageRangeInputComponent,
	TimeInputComponent,
	TimeRangeInputComponent,
	DateInputComponent,
	DateRangeInputComponent,
	LineChartComponent
  ],
  providers: [
  MatDatepickerModule,
  MatNativeDateModule,
// { provide: FIREBASE_ANALYTICS_APP_NAME, useValue: BUILD_SOURCE },
// { provide: FIREBASE_ANALYTICS_APP_VERSION, useValue: BUILD_COMMIT },
// { provide: FIREBASE_ANALYTICS_DEBUG_MODE, useValue: environment.faDebug },
  ],
  })
export class SharedModule {}
